import { type FC, type Dispatch, type SetStateAction, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { RadioCheckBoxes, ImageSlider, MaxHeightContainer, Link, Price } from '@dy/commons/components'
import { useTranslator, useCustomer } from '@/hooks'
import { getFilterLabels, FILTER_LABELS, getPricePerUnit, AddToCart } from '../'
import { mq, vw, createUnderline, getP30, getP20_2,  getP18_3, getP18_1, getP15_1B, getP15, getP12_1B} from '@dy/commons/styles'
import type { TypeProduct, TypeVariant } from '@dy/commons/schema'
import type { TypeTranslationsJsonKeys } from '@/context'
import { getDynamicText, getDayOfWeekName, getLang, getDateFormatted } from '@dy/commons/utils'
import { getPathnameType, hasPrivateStore } from '@dy/commons/utils'


type Props = {
  data: TypeProduct,
  labels?: any,
  isPack?: boolean
  currentVariant: TypeVariant,
  setCurrentVariant: Dispatch<SetStateAction<TypeVariant>>,
  round?: boolean,
  isPrivate?: boolean
}

const getAvailableVariants = (variants, currentVariant, setCurrentVariant) => variants.map((variant) => {

  const { name } = variant
  return {
    variant,
    currentVariantId: currentVariant.bigcommerceId,
    setCurrentVariant,
    text: `${name}`
  }
})

const getUnifiedLabel = (deliveryType, customer, labels, onDemandText) => {
  let incomingLabels = []

  if(Array.isArray(labels) && labels.length > 0) incomingLabels = [...labels]
  if (customer && deliveryType === 'on_demand') incomingLabels.push({ name: onDemandText })

  return incomingLabels
}

const getLabels = labels => labels.map((label, idx) => <span key={idx}>{label.name}</span>)

export const DetailHeader:FC<Props> = memo(({ data, labels = [], isPack = false, currentVariant, setCurrentVariant, round, isPrivate = true }) => {
  const { name, brand, variants, description, categories, filters } = data
  const { id: variant_id, media, name: variant_name, price, reference_price, reference_unit, stock, stockWarning, minSaleUnits, deliveryType, deliveryDate, onDemandDay, product } = currentVariant as TypeVariant
  const { inventoryTracking } = product

  const { customer } = useCustomer()
  const { t } = useTranslator()
  const { locale:routerLocale, pathname } = useRouter()
  const lang = getLang(routerLocale)
  const isPrivatePath = useMemo(() => getPathnameType(pathname) === 'private',[pathname])
  const isPanettone = name.toLowerCase().includes('panettone')
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableVariants = useMemo(() => getAvailableVariants(variants, currentVariant, setCurrentVariant), [variants, currentVariant, setCurrentVariant ])

  const { discount } = price || {}
  const LabelDiscount = useMemo(() => discount > 0 ? <span className='discount'>-{discount}%</span> : <></>, [discount])

  const unifiedLabels = useMemo(() => getUnifiedLabel(deliveryType, customer, labels, t('product_page.product.demand_product') as string), [customer, labels, deliveryType, t])

  const LabelItems = useMemo(() => unifiedLabels ? getLabels(unifiedLabels) : [], [unifiedLabels])
  const preselectFilters = useMemo(() => filters?.filter(item => FILTER_LABELS[item.param] && item?.values?.[0]?.visible), [filters])
  const LabelFilters = useMemo(() => preselectFilters?.length > 0 ? getFilterLabels(preselectFilters, pathname) : <></>, [preselectFilters, pathname])

  const displayStockWarning = inventoryTracking && stock >= 1 && stockWarning

  return (
    <Header isPack={isPack}>
      {!isPack &&
        <Brand>
          {brand !== null && <Link href={isPrivate ? `/shop/brand/${brand.slug[lang]}` : `/brand/${brand.slug[lang]}`}>{brand.name}</Link> }
        </Brand>
      }
      <h1>{name}</h1>
      <MediaWrapper>
        {(LabelItems.length > 0 || LabelFilters?.length > 0 || LabelDiscount)  &&
        <LabelWrapper>
          {LabelItems}
          {LabelFilters}
          {LabelDiscount}
        </LabelWrapper>
        }
        <ImageSlider data={media} isPack={isPack} key={variant_id}/>
      </MediaWrapper>
      {!isPack &&
        <ProductInfo className={availableVariants?.length === 1 ? 'single-variant' : ''} >
          {availableVariants?.length === 1 ?
            <>
              {minSaleUnits > 1 ?
                <label>{variant_name}</label>
                :
                <label>{variant_name}{getPricePerUnit(reference_price, reference_unit, false, routerLocale)}</label>
              }
            </>
            :
            <RadioCheckBoxes data={availableVariants} />
          }
          {(minSaleUnits > 1) && isPrivate && <span>
            {getDynamicText(t('product_page.product.description_min_sale_units') as string, {
              minSaleUnits:minSaleUnits
            })}
          </span>}
        </ProductInfo>
      }
      <FlexWrap $isPrivate={isPrivate}>
        {(price !== null || reference_price !== null) && <Price type='pdp' price={price ?? getPricePerUnit(reference_price, reference_unit, false, routerLocale)} pricePer={t('product_page.product.price_per.unit') as string} round={round}/>}
        <AddToCart data={{ name, brand, categories, variant: currentVariant, product: currentVariant.product }} stock={stock} isPDP={true} displayStockWarning={displayStockWarning} notAvailable={price === null && stock > 0} inventoryTracking={inventoryTracking} shouldTriggerHowToBuyModal={!isPanettone}/>
      </FlexWrap>
      {(hasPrivateStore[routerLocale] && isPrivatePath) && <SpanDeliveryDate>{getDynamicText(t(`product_page.product.delivery_date_${deliveryType}` as TypeTranslationsJsonKeys) as string, {
        deliveryDate: getDateFormatted(lang, deliveryDate),
        onDemandDay: getDayOfWeekName(lang, onDemandDay),
        deliveryHour: deliveryType === 'on_demand' ? '14h' : '21h'
      })}</SpanDeliveryDate>}
      <MaxHeightContainer force_link={true} className='description-clamp' elipsis={t('product_page.product_main.see_more')} data={description} />
    </Header>
  )
})


const Header = styled.header<any>`
  h1 {
    ${getP30()}
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    margin-bottom: ${({ isPack }) => vw(isPack ? 42 : 19 , 'mobile')};
    text-align: left;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${({ isPack }) => vw(isPack ? 42 : 19 , 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${({ isPack }) => isPack ? `${vw(10, 'desktop')}` : '0'};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: ${({ isPack }) => isPack ? '10px' : '0'};
    }
  }

  .description-clamp {
    ${getP18_3()}
    color: ${({ theme }) => theme.colors.red};
    height: ${vw(174, 'mobile')};
    margin: ${vw(40, 'mobile')} 0 ${vw(60, 'mobile')};
    overflow: hidden;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(154, 'nexus7')};
      margin: ${vw(40, 'nexus7')} 0 ${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      ${getP20_2()}
      height: ${vw(210, 'desktop')};
      margin: ${vw(40, 'desktop')} 0 ${vw(80, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 210px;
      margin: 40px 0 80px;
    }

    button {
      ${getP18_3()}
      font-family: ${({ theme }) => theme.fonts.secondaryFont};
      color: ${({ theme }) => theme.colors.red};
      transition: 200ms opacity ease-in-out;
      ${createUnderline({ reverse: true, color: 'red' })}

      &:hover {
        opacity: 1;
      }

      ${mq.greaterThan('tablet')} {
        ${getP20_2()}
      }
    }
  }

  .last_units, .on_demand {
    ${getP15()}
    font-weight: 500;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    color: ${({ theme }) => theme.colors.orange};
    display: block;
    margin-top: ${vw(15, 'mobile')};
    text-align: center;

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(15, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 15px;
    }
  }
`



const Brand = styled.span`
  ${getP18_1()}
  ${createUnderline({ height: 0.5, color: 'red' })}
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 10px;
  }
`


const ProductInfo = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.secondary};
  justify-content: center;
  margin-bottom: ${vw(10, 'mobile')};
  flex-direction: column;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 10px;
  }

  &.single-variant {
    display: block;

    label {
      ${getP15_1B()}
      font-weight: 500;
    }
  }

  label {
    ${getP15()}
    font-weight: 500;
    display: block;

  }

  label, span {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    color: ${({ theme }) => theme.colors.red};
  }

  div + span {
    margin-top: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 30px;
    }
  }

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 ${vw(15, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin: 0 ${vw(15, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin: 0 ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 0 15px;
    }

    > div{
      margin: 0 ${vw(15, 'mobile')} ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin: 0 ${vw(15, 'nexus7')} ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin: 0 ${vw(15, 'desktop')} ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin: 0 15px 10px;
      }
    }
  }

  input {
    position: absolute;
    margin: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
`

const FlexWrap = styled.div<any>`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content:  ${({ $isPrivate }) => $isPrivate ? 'space-between' : 'flex-end' };
  padding: 0 0 ${vw(35, 'mobile')};
  row-gap: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    padding: 0 0 ${vw(35, 'nexus7')};
    row-gap: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding: 0 0 ${vw(25, 'desktop')};
    row-gap: ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 0 0 25px;
    row-gap: 20px;
  }

  button {
    ${getP15_1B()}
  }
`


const MediaWrapper = styled.div`
  position: relative;
`

const LabelWrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${vw(15, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    top: ${vw(15, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    top: ${vw(15, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    top: 15px;
  }

  span {
    ${getP12_1B()}
    background-color: ${({ theme }) => theme.colors.darkcreme};
    border-radius: ${vw(12, 'mobile')};
    color: ${({ theme }) => theme.colors.white};
    margin-right: ${vw(5, 'mobile')};
    padding: ${vw(5, 'mobile')} ${vw(8, 'mobile')};
    text-transform: uppercase;
    z-index: 1;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(12, 'nexus7')};
      margin-right: ${vw(5, 'nexus7')};
      padding: ${vw(5, 'nexus7')} ${vw(8, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(12, 'desktop')};
      margin-right: ${vw(5, 'desktop')};
      padding: ${vw(5, 'desktop')} ${vw(8, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 12px;
      margin-right: 5px;
      padding: 5px 8px ;
    }
  }

  .fresh {
    text-transform: none;
  }

  .discount {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .filter-label {
    background-color: unset;
    height: ${vw(24, 'mobile')};
    margin-right: ${vw(5, 'mobile')};
    width: ${vw(24, 'mobile')};
    z-index: 1;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(24, 'nexus7')};
      margin-right: ${vw(5, 'nexus7')};
      width: ${vw(24, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(24, 'desktop')};
      margin-right: ${vw(5, 'desktop')};
      width: ${vw(24, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 24px;
      margin-right: 5px;
      width: 24px;
    }
  }
`

const SpanDeliveryDate = styled.span`
  display: block;
  ${getP15()}
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  opacity: .4;
  width: 100%;
`
